<template>
  <div>
    <information-form-candidate/>
  </div>
</template>

<script>
  import InformationFromCandidate from './../../components-globalwork/projects/information-form-candidate.vue'
  export default {
    components: {
      'information-form-candidate': InformationFromCandidate,
    },
  }
</script>

<style lang="scss" scoped>

</style>