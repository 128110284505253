var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticStyle: { "margin-top": "1rem" } }, [
      _c(
        "div",
        {
          staticStyle: {
            width: "700px",
            height: "328px",
            margin: "0px auto",
            "background-color": "#5F0091",
            "border-radius": "25px 25px 0px 0px",
          },
        },
        [
          _c("div", { staticStyle: { width: "507px", margin: "0px auto" } }, [
            _c("img", {
              staticStyle: {
                "margin-top": "80px",
                "text-align": "center",
                "margin-left": "150px",
              },
              attrs: {
                src: "https://res.cloudinary.com/dhcti0lrk/image/upload/v1603742949/globalwork/Logo-todo-en-blanco_1_nnvqam.png",
              },
            }),
            _c("br"),
            _c(
              "p",
              {
                staticStyle: {
                  color: "#FFFFFF",
                  "font-family": "Montserrat",
                  "font-size": "36px",
                  "font-style": "normal",
                  "font-weight": "800",
                  "line-height": "44px",
                  "letter-spacing": "0em",
                  "text-align": "center",
                },
              },
              [
                _c("b", { staticStyle: { color: "#FFC057" } }, [
                  _vm._v("Optimizamos tus tiempos:"),
                ]),
                _vm._v(
                  " ahora los candidatos pueden subir su propia información\n      "
                ),
              ]
            ),
          ]),
        ]
      ),
      _c(
        "div",
        {
          staticStyle: {
            width: "572px",
            height: "900px",
            margin: "0px auto",
            "background-color": "FFFFFF",
            "border-radius": "0px 0px 25px 25px",
            "text-align": "left",
            "padding-top": "40px",
            "padding-left": "64",
            "padding-right": "64px",
          },
        },
        [
          _c(
            "p",
            {
              staticStyle: {
                "font-family": "Montserrat",
                "font-style": "normal",
                "font-weight": "normal",
                "font-size": "16px",
                "line-height": "26px",
                color: "#3C1B4E",
                "margin-bottom": "1rem",
              },
            },
            [
              _vm._v(
                "\n      En los últimos meses hemos trabajado para seguir mejorando nuestro servicio y ofrecerte mejores soluciones.\n    "
              ),
            ]
          ),
          _c(
            "p",
            {
              staticStyle: {
                "font-family": "Montserrat",
                "font-style": "normal",
                "font-weight": "normal",
                "font-size": "16px",
                "line-height": "26px",
                color: "#3C1B4E",
                "margin-bottom": "1rem",
              },
            },
            [
              _c("b", { staticStyle: { "font-weight": "600" } }, [
                _vm._v(
                  "Hemos notado que gran parte del tiempo, tanto tuyo como nuestro, se invierte en solicitarle al candidato todos los certificados, lo que impacta significativamente en los tiempos de verificación y contratación."
                ),
              ]),
            ]
          ),
          _c(
            "p",
            {
              staticStyle: {
                "font-family": "Montserrat",
                "font-style": "normal",
                "font-weight": "normal",
                "font-size": "16px",
                "line-height": "26px",
                color: "#3C1B4E",
                "margin-bottom": "1rem",
              },
            },
            [
              _vm._v(
                "\n      Buscando optimizar estos tiempos, hemos creado una funcionalidad para que sean los mismos aspirantes quienes adjunten los certificados y diligencien la información personal, laboral y académica desde el momento que se cree el proceso.\n    "
              ),
            ]
          ),
          _c(
            "p",
            {
              staticStyle: {
                "font-family": "Montserrat",
                "font-style": "normal",
                "font-weight": "normal",
                "font-size": "16px",
                "line-height": "26px",
                color: "#3C1B4E",
                "margin-bottom": "1rem",
              },
            },
            [
              _c("b", { staticStyle: { "font-weight": "600" } }, [
                _vm._v(
                  "Esta opción solo se activará con tu autorización previa y puedes hacerlo de esta manera:"
                ),
              ]),
            ]
          ),
          _c(
            "p",
            {
              staticStyle: {
                "font-family": "Montserrat",
                "font-style": "normal",
                "font-weight": "normal",
                "font-size": "16px",
                "line-height": "26px",
                color: "#3C1B4E",
                "margin-bottom": "1rem",
              },
            },
            [
              _vm._v(
                "\n      1 - Cuando estés creando un proceso, llegarás a la sección de "
              ),
              _c("b", { staticStyle: { "font-weight": "600" } }, [
                _vm._v("“Información Adicional”"),
              ]),
              _vm._v(".\n    "),
            ]
          ),
          _c(
            "p",
            {
              staticStyle: {
                "font-family": "Montserrat",
                "font-style": "normal",
                "font-weight": "normal",
                "font-size": "16px",
                "line-height": "26px",
                color: "#3C1B4E",
                "margin-bottom": "1rem",
              },
            },
            [
              _vm._v("\n      2 - Allí, encontrarás una opción que dice "),
              _c("b", { staticStyle: { "font-weight": "600" } }, [
                _vm._v(
                  "“¿Nos autorizas a solicitar al candidato información sobre sus referencias académicas, laborales y personales? Esto optimiza el proceso, pues el candidato es el responsable de compartirnos los datos necesarios”."
                ),
              ]),
              _vm._v(
                " Si nos autorizas, haz clic en la caja de selección. En caso de seleccionar esta opción, no debes adjuntarnos ningún documento.\n    "
              ),
            ]
          ),
          _c(
            "p",
            {
              staticStyle: {
                "font-family": "Montserrat",
                "font-style": "normal",
                "font-weight": "normal",
                "font-size": "16px",
                "line-height": "26px",
                color: "#3C1B4E",
                "margin-bottom": "1rem",
              },
            },
            [
              _vm._v(
                "\n      3 - Finaliza la creación del proceso de manera normal.      \n    "
              ),
            ]
          ),
          _c(
            "p",
            {
              staticStyle: {
                "font-family": "Montserrat",
                "font-style": "normal",
                "font-weight": "normal",
                "font-size": "16px",
                "line-height": "26px",
                color: "#3C1B4E",
                "margin-bottom": "1rem",
              },
            },
            [
              _c("b", { staticStyle: { "font-weight": "600" } }, [
                _vm._v(
                  "Nos encantaría escuchar tus comentarios y solucionar las dudas que surjan al respecto."
                ),
              ]),
            ]
          ),
          _c(
            "p",
            {
              staticStyle: {
                "font-family": "Montserrat",
                "font-style": "normal",
                "font-weight": "normal",
                "font-size": "22px",
                "line-height": "32px",
                color: "#3C1B4E",
                "margin-bottom": "1rem",
              },
            },
            [
              _vm._v(
                "\n      ¡Seguimos trabajando por crear vínculos confiables entre tu empresa y el talento humano!\n    "
              ),
            ]
          ),
          _c("hr", {
            staticStyle: {
              width: "428px",
              height: "0px",
              border: "2px solid #F8F1FC",
              "margin-top": "32px",
            },
          }),
          _c(
            "p",
            {
              staticStyle: {
                "text-align": "center",
                "font-family": "Montserrat",
                "font-style": "normal",
                "font-weight": "600",
                "font-size": "16px",
                "line-height": "26px",
                color: "#3C1B4E",
              },
            },
            [
              _vm._v(
                "\n      Copyright © 2020 Globalwork | All Rights Reserved\n    "
              ),
            ]
          ),
          _c(
            "p",
            {
              staticStyle: {
                "text-align": "center",
                "font-family": "Montserrat",
                "font-style": "normal",
                "font-weight": "normal",
                "font-size": "14px",
                "line-height": "23px",
                color: "#3C1B4E",
              },
            },
            [
              _vm._v(
                "\n      Transversal 39 B # 75 - 25 Int. 201 Medellín, Colombia\n    "
              ),
            ]
          ),
        ]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }