<template>
  <div style="margin-top: 1rem;">
    <div style="width: 700px; height: 328px; margin: 0px auto; background-color: #5F0091; border-radius: 25px 25px 0px 0px;">    
      <div style="width: 507px; margin: 0px auto">
        <img style="margin-top: 80px; text-align: center; margin-left: 150px;" src="https://res.cloudinary.com/dhcti0lrk/image/upload/v1603742949/globalwork/Logo-todo-en-blanco_1_nnvqam.png"> <br>
        <p style="color: #FFFFFF; font-family: Montserrat; font-size: 36px; font-style: normal; font-weight: 800; line-height: 44px; letter-spacing: 0em; text-align: center;">
          <b style="color: #FFC057;">Optimizamos tus tiempos:</b> ahora los candidatos pueden subir su propia información
        </p>
      </div>    
    </div>
    <div style="width: 572px; height: 900px; margin: 0px auto; background-color: FFFFFF; border-radius: 0px 0px 25px 25px; text-align: left; padding-top: 40px; padding-left: 64; padding-right: 64px;">
      <p style="font-family: Montserrat; font-style: normal; font-weight: normal; font-size: 16px; line-height: 26px; color: #3C1B4E; margin-bottom: 1rem;">
        En los últimos meses hemos trabajado para seguir mejorando nuestro servicio y ofrecerte mejores soluciones.
      </p>
      <p style="font-family: Montserrat; font-style: normal; font-weight: normal; font-size: 16px; line-height: 26px; color: #3C1B4E; margin-bottom: 1rem;">
        <b style="font-weight: 600;">Hemos notado que gran parte del tiempo, tanto tuyo como nuestro, se invierte en solicitarle al candidato todos los certificados, lo que impacta significativamente en los tiempos de verificación y contratación.</b>
      </p>
      <p style="font-family: Montserrat; font-style: normal; font-weight: normal; font-size: 16px; line-height: 26px; color: #3C1B4E; margin-bottom: 1rem;">
        Buscando optimizar estos tiempos, hemos creado una funcionalidad para que sean los mismos aspirantes quienes adjunten los certificados y diligencien la información personal, laboral y académica desde el momento que se cree el proceso.
      </p>
      <p style="font-family: Montserrat; font-style: normal; font-weight: normal; font-size: 16px; line-height: 26px; color: #3C1B4E; margin-bottom: 1rem;">
        <b style="font-weight: 600;">Esta opción solo se activará con tu autorización previa y puedes hacerlo de esta manera:</b> 
      </p>
      <p style="font-family: Montserrat; font-style: normal; font-weight: normal; font-size: 16px; line-height: 26px; color: #3C1B4E; margin-bottom: 1rem;">
        1 - Cuando estés creando un proceso, llegarás a la sección de <b style="font-weight: 600;">“Información Adicional”</b>.
      </p>
      <p style="font-family: Montserrat; font-style: normal; font-weight: normal; font-size: 16px; line-height: 26px; color: #3C1B4E; margin-bottom: 1rem;">
        2 - Allí, encontrarás una opción que dice <b style="font-weight: 600;">“¿Nos autorizas a solicitar al candidato información sobre sus referencias académicas, laborales y personales? Esto optimiza el proceso, pues el candidato es el responsable de compartirnos los datos necesarios”.</b> Si nos autorizas, haz clic en la caja de selección. En caso de seleccionar esta opción, no debes adjuntarnos ningún documento.
      </p>
      <p style="font-family: Montserrat; font-style: normal; font-weight: normal; font-size: 16px; line-height: 26px; color: #3C1B4E; margin-bottom: 1rem;">
        3 - Finaliza la creación del proceso de manera normal.      
      </p>    
      <p style="font-family: Montserrat; font-style: normal; font-weight: normal; font-size: 16px; line-height: 26px; color: #3C1B4E; margin-bottom: 1rem;">
        <b style="font-weight: 600;">Nos encantaría escuchar tus comentarios y solucionar las dudas que surjan al respecto.</b>
      </p>
      <p style="font-family: Montserrat; font-style: normal; font-weight: normal; font-size: 22px; line-height: 32px; color: #3C1B4E; margin-bottom: 1rem;">
        ¡Seguimos trabajando por crear vínculos confiables entre tu empresa y el talento humano!
      </p> 
      <hr style="width: 428px; height: 0px; border: 2px solid #F8F1FC; margin-top: 64px; margin-top: 32px;">   
      <p style="text-align: center; font-family: Montserrat; font-style: normal; font-weight: 600; font-size: 16px; line-height: 26px; color: #3C1B4E;">
        Copyright © 2020 Globalwork | All Rights Reserved
      </p>     
      <p style="text-align: center; font-family: Montserrat; font-style: normal; font-weight: normal; font-size: 14px; line-height: 23px; color: #3C1B4E;">
        Transversal 39 B # 75 - 25 Int. 201 Medellín, Colombia
      </p>         
    </div>
  </div>
</template>

<script>
  export default {
        
  }
</script>

<style scoped>
  body{
    text-align: center; 
    background-color: #E5E5E5;
  }
</style>